<script>
import userService from "@/services/user";
import companyService from "@/services/company";
import { USER_ROLES } from "@/services/constants";
import Spinner from "@/components/ui/Spinner";
import FileUpload from "@/components/ui/FileUpload";
import fileUploadConfigService from "@/services/fileupload-config";
import segmentService from "@/services/segment";
import institutionService from "@/services/institution";
import medicalSpecialtyService from "@/services/medical-specialty";
import leave from "@/mixins/leave";

import { enableScores } from "@/services/config";

const { ADMIN, MANAGER, DOCTOR, ASSISTANT, SCORES, PATIENT } = USER_ROLES;
const CONFIG_TYPE = "users";

export default {
  name: "UserDetail",

  components: {
    Spinner,
    FileUpload,
  },

  mixins: [leave],

  data() {
    return {
      useScores: enableScores,
      companies: [],
      institutions: [],
      medicalSpecialties: [],
      USER_ROLES,
      configId: this.$route.params.id,
      user: {
        patient: null,
        firstName: "",
        lastName: "",
        publicId: "",
        email: "",
        phone: "",
        doctor: {},
        role: DOCTOR.id,
        supervisor: null,
      },
      files: [],
      supervisors: [],
      isUpdate: this.$route.name === "update-user",
      isFetchingUser: false,
      isPostingUser: false,
      isLoadingSupervisors: false,
      hasFiles: false,
    };
  },

  computed: {
    roleOptions() {
      let roles = [DOCTOR, MANAGER];
      if (this.$store.state.user.role === ADMIN.name) {
        roles.push(ADMIN, ASSISTANT);

        if (enableScores) {
          roles.push(SCORES);
        }
      }

      if (this.isUpdate) {
        let userRole = this.getRoleName(this.user.role);
        // admins and assistants will only have permission to change to admin or assistant
        if (userRole === ADMIN.key || userRole === ASSISTANT.key) {
          roles = roles.filter((role) => {
            return role.key === ADMIN.key || role.key === ASSISTANT.key;
          });
        }
      }

      return roles;
    },

    isRoleChangeAllowed() {
      if (!this.isUpdate) {
        return true;
      }
      const userRole = this.getRoleName(this.user.role);
      if (
        userRole === DOCTOR.key ||
        userRole === MANAGER.key ||
        userRole === PATIENT.key
      ) {
        return false;
      }
      return true;
    },

    sectors() {
      if (!this.user.company) {
        return [];
      }

      const company = this.companies.find((c) => c._id === this.user.company);

      return company?.sectors;
    },

    uploadMediaEndpoint() {
      return fileUploadConfigService.getUploadAvatarEndpoint(
        CONFIG_TYPE,
        this.configId
      );
    },
  },

  created() {
    this.getUser();
    this.getSupervisors();
    this.getInstitutions();
    this.getMedicalSpecialties();

    if (this.useScores) {
      this.$set(this.user, "company", null);
      this.$set(this.user, "companySector", null);
      this.getCompanies();
    }
  },

  mounted() {
    document.addEventListener("keyup", this.escape);
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.escape);
  },

  methods: {
    getRoleName(roleId) {
      for (const role in USER_ROLES) {
        if (USER_ROLES[role].id === roleId) {
          return USER_ROLES[role].key;
        }
      }
    },

    escape(event) {
      if (event.keyCode == 27) this.goToRoute("users-list");
    },

    getManagers() {
      userService.getManagers().then(({ docs }) => (this.suppervisors = docs));
    },

    getCompanies() {
      companyService.get().then(({ docs }) => (this.companies = docs));
    },

    submit() {
      this.createOrUpdateUser();
    },

    async createOrUpdateUser() {
      const eventName = this.isUpdate ? "User Updated" : "User Created";
      segmentService.track({ name: eventName });

      this.isPostingUser = true;
      this.showLeaveGuard = false;
      const serviceCall = this.isUpdate
        ? userService.update
        : userService.create;

      if (
        !this.user.doctor.institutions &&
        !this.user.doctor.medicalSpecialties
      ) {
        delete this.user.doctor;
      } else {
        if (!this.user.doctor.institutions) {
          delete this.user.doctor.instutions;
        }

        if (!this.user.doctor.medicalSpecialties) {
          delete this.user.doctor.medicalSpecialties;
        }
      }

      try {
        const { data } = await serviceCall(this.user);
        this.$bus.$emit("user-update", data || this.user);

        this.configId = this.configId || data._id;
        if (this.hasFiles) {
          this.handleUploadFiles();
        } else {
          this.goToRoute("users-list");
        }
      } finally {
        this.isPostingUser = false;
      }
    },

    getUser() {
      if (this.isUpdate) {
        this.isFetchingUser = true;
        userService
          .getById(this.$route.params.id)
          .then((user) => {
            if (!user.doctor) {
              user.doctor = {};
            }
            this.user = user;
            this.user.role = user.role._id;
          })
          .finally(() => {
            this.isFetchingUser = false;
          });
      }
    },

    getSupervisors() {
      this.isLoadingSupervisors = true;
      userService
        .get({ roles: [MANAGER.id] })
        .then((res) => {
          this.supervisors = res.docs;
        })
        .finally(() => (this.isLoadingSupervisors = false));
    },

    async getMedicalSpecialties() {
      this.isLoading = true;

      medicalSpecialtyService
        .getMedicalSpecialties()
        .then((res) => {
          this.medicalSpecialties = res;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    async getInstitutions() {
      this.isLoading = true;

      institutionService
        .getInstitutions()
        .then((res) => {
          this.institutions = res;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onInputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
      }
    },

    getModalName() {
      if (this.isUpdate) {
        return "Actualizar Usuario";
      }
      return "Nuevo Usuario";
    },

    goToRoute(name) {
      this.$router.push({ name });
    },

    handleUploadFiles() {
      this.$refs.fileUpload && this.$refs.fileUpload.startUpload();
    },

    getFiles() {
      return this.user.avatar && this.user.avatar.key ? [this.user.avatar] : [];
    },

    getDropdownOptions() {
      return {
        method: "put",
        paramName: "avatar",
        maxFiles: 1,
      };
    },

    uploadFilesSuccess() {
      this.goToRoute("users-list");
    },

    uploadFilesError() {
      this.isPostingUser = false;
    },

    onFileAdded() {
      this.hasFiles = true;
    },

    onFileRemoved() {
      this.hasFiles = false;
      this.user.avatar = null;
    },
  },
};
</script>

<template lang="pug">
  ValidationObserver(v-slot="{handleSubmit}")
    form.modal(@submit.prevent="handleSubmit(submit)")
      header.modal__header
        h2.modal__title {{ getModalName() }}
        .modal__actions
          el-button(type="info" @click="goToRoute('users-list')") Cancelar
          el-button.border(type="primary" native-type="submit" :loading="isPostingUser") Guardar
      p.modal__subtitle Los campos con (*) son obligatorios
      .modal__content(v-if="isFetchingUser")
        spinner
      .modal__content(v-else)
        // Personal Info Section
        .modal__block
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="person")
              h3.sign__title Información Personal
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  label.label * Nombre
                  ValidationProvider(name="Nombre", rules="required", v-slot="{ errors }")
                    el-input(v-model="user.firstName", autofocus)
                    span.has-error {{ errors[0] }}
              .modal__row
                fieldset.modal__field
                  label.label * Apellido
                  ValidationProvider(name="Apellido", rules="required", v-slot="{ errors }")
                    el-input(v-model="user.lastName")
                    span.has-error {{ errors[0] }}
              .modal__row
                fieldset.modal__field
                  label.label ID Proveedor
                  el-input(v-model="user.publicId")
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  label.label Foto
                  file-upload(
                    ref="fileUpload",
                    type="image"
                    :url="uploadMediaEndpoint",
                    :files="getFiles()",
                    :dropzone-options="getDropdownOptions()"
                    @file-added="onFileAdded",
                    @files-removed="onFileRemoved",
                    @fileupload-complete="uploadFilesSuccess"
                    @fileupload-error="uploadFilesError"
                  )
        // Contact Section
        .modal__block
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="forum")
              h3.sign__title Contacto
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  label.label * Email
                  ValidationProvider(name="Email", rules="required|email", v-slot="{ errors }")
                    el-input(type="email", v-model="user.email", :disabled="isUpdate")
                    span.has-error {{ errors[0] }}
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  label.label Teléfono
                  el-input(v-model="user.phone", name="Teléfono")
        .modal__block
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="lock")
              h3.sign__title Seguridad
            article.modal__fields
              .modal__row
                fieldset.modal__field.role-options
                  label.label * Rol
                  el-select(v-model="user.role" required :disabled="!isRoleChangeAllowed")
                    el-option(
                      :key="role.key"
                      v-for="role in roleOptions"
                      v-auth="`${role.key}.create`"
                      :value="role.id"
                      :label="role.name"
                    )

                fieldset.modal__field(v-if="user.role === USER_ROLES.DOCTOR.id")
                  label.label * Médico coordinador
                  el-select(
                    v-model="user.supervisor"
                    :disabled="isLoadingSupervisors"
                    required
                  )
                    el-option(
                      v-for="supervisor in supervisors"
                      :key="supervisor.id"
                      :value="supervisor.id"
                      :label="`${supervisor.lastName} ${supervisor.firstName}`"
                    )

        .modal__block(v-if="user.role === USER_ROLES.DOCTOR.id && user.doctor")
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="institution")
              h3.sign__title Intituciones
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  label.label Instituciones
                  el-select(v-model="user.doctor.institutions" placeholder="" filterable multiple clearable default-first-option)
                    el-option(
                      v-for="i in institutions"
                      :key="i._id"
                      :label="i.name"
                      :value="i._id"
                    )
        .modal__block(v-if="user.role === USER_ROLES.DOCTOR.id && user.doctor")
            .modal__section
              .modal__sign.sign
                .sign__icon
                  micon(name="medical-specialty")
                h3.sign__title Especialidades médicas
              article.modal__fields
                .modal__row
                fieldset.modal__field
                  label.label Especialidades médicas
                  el-select(v-model="user.doctor.medicalSpecialties" placeholder="" filterable multiple clearable default-first-option)
                    el-option(
                      v-for="m in medicalSpecialties"
                      :key="m._id"
                      :label="m.name"
                      :value="m._id"
                    )

        // Company and Sector section
        .modal__block(v-show="useScores && user.role === USER_ROLES.SCORES.id")
          .modal__section
            .modal__sign.sign
              .sign__icon
                micon(name="videogame")
              h3.sign__title Scoring
            article.modal__fields
              .modal__row
                fieldset.modal__field
                  label.label * Empresa
                  el-select(v-model="user.company" placeholder="" filterable clearable default-first-option)
                    el-option(
                      v-for="c in companies"
                      :key="c._id"
                      :label="c.name"
                      :value="c._id"
                    )
</template>

<style lang="scss" scoped>
.role-options {
  max-width: 50%;
}
</style>
